<template>
  <div id="nav">
    <div class="content">
      <div class="logo">广州有福哩科技有限公司</div>
      <ul class="list">
        <li
          v-for="item in routerItems"
          :key="item.value"
          :class="item.value === routerName ? 'active' : ''"
          @click="navChange({ value: item.value, type: 'click' })"
          class="options"
        >{{ item.name }}</li>
      </ul>
    </div>
  </div>
  <div class="main">
    <router-view :routerItems="routerItems" :routerName="routerName" @getNavChange="navChange"/>
  </div>
  <div class="floot">
    <img src="@/assets/qrcode_for_gh_7ffa21bbab2e_258.jpg" alt="">
    <p>广州有福哩科技有限公司</p>
    <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021002882号-1</a>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { useRouter } from 'vue-router'

export default ({
  setup () {
    const state = reactive({
      routerItems: [
        {
          name: '首页',
          value: 'Home'
        },
        {
          name: '主营项目',
          value: 'Project'
        },
        {
          name: '主要服务',
          value: 'Services'
        },
        {
          name: '关于我们',
          value: 'About'
        }
      ],
      routerName: 'Home',
      routerType: ''
    })
    const navChange = ({ value, type }) => {
      if (state.routerType) return
      state.routerType = type
      setTimeout(() => {
        state.routerType = ''
      }, 1000)
      if (value !== state.routerName) {
        state.routerName = value
      }
    }

    return {
      ...toRefs(state),
      navChange
    }
  }
})
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html,body,div,h1,h2,h3,strong,span,img,a,p,label,ul,li,dl,dt,dd,ol,table,tr,td,input,nav,section,header,footer,i {
  margin: 0;
  padding:0;
  -webkit-tap-highlight-color:rgba(255,0,0,0);
}

ul li {
  list-style-type: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  z-index: 10;
  .content {
    height: 100%;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
    }
    .list {
      display: flex;
      height: 100%;
      .options {
        height: 100%;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        padding: 0 30px;
        text-decoration: none;
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          color: #ea0e0e;
        }
        &.active {
         color: #ea0e0e;
        }
        &.active::after {
          content: '';
          position: absolute;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 5px;
          background-color: #ea0e0e;
        }
      }
    }
  }
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.content {
  width: 1200px;
  margin: 0 auto;
}
.main {
  padding-top: 80px;
}
.floot {
  width: 100%;
  height: 200px;
  background-color: #333333;
  box-sizing: border-box;
  padding: 0 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  img {
    display: block;
    width: 100px;
    height: auto;
    margin-bottom: 10px;
  }
  p {
    line-height: 20px;
    font-size: 14px;
    color: #f4f4f4;
  }
  a {
    line-height: 20px;
    font-size: 14px;
    color: #f4f4f4;
  }
}
</style>
